/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/mojocss@0.2.0/dist/mojo.min.js
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/bootstrap@4.6.2/dist/js/bootstrap.min.js
 * - /npm/jquery-ui@1.13.2/dist/jquery-ui.min.js
 * - /npm/fancybox@2.1.5/dist/js/jquery.fancybox.pack.js
 * - /gh/Frogmouth/jquery.tickerNews@master/jquery.tickerNews.min.js
 * - /npm/blueimp-md5@2.12.0/js/md5.min.js
 * - /npm/jquery.cookie@1.4.1/jquery.cookie.min.js
 * - /npm/jquery.marquee@1.6.0/jquery.marquee.min.js
 * - /npm/jquery-match-height@0.7.2/dist/jquery.matchHeight-min.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/bxslider@4.2.14/dist/jquery.bxslider.min.js
 * - /npm/jquery-ajax-unobtrusive@3.2.6/dist/jquery.unobtrusive-ajax.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
